import React, { Component } from 'react';

class Main extends Component {
    constructor(props)
    {
      super(props);
      this.state = {
      };
    }
    componentDidMount()
      {
      }

      render() {
        return(
            <div>
                <div class="row">
                <div class="decorate-title col-md-4" id="decorate-title">
                    <button class="button-decorate-collapse" onclick="collapseDecorateContainRight(this)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.25 17H20.25C20.664 17 21 17.336 21 17.75C21 18.164 20.664 18.5 20.25 18.5H11.25C10.836 18.5 10.5 18.164 10.5 17.75C10.5 17.336 10.836 17 11.25 17ZM20.25 6.5H11.25C10.836 6.5 10.5 6.164 10.5 5.75C10.5 5.336 10.836 5 11.25 5H20.25C20.664 5 21 5.336 21 5.75C21 6.164 20.664 6.5 20.25 6.5Z" fill="white"/>
                            <path d="M3 11.75L8.25 7.25V11H20.25C20.664 11 21 11.336 21 11.75C21 12.164 20.664 12.5 20.25 12.5H8.25V16.25L3 11.75Z" fill="white"/>
                            </svg>
                            
                        <span class="fadeshow-sm decoration-editing-text mx-2 Sub-Title">Decoration Editing</span>
                    </button>
                </div>
                <div class="product-list">
                    <button class=" m-2 background-black">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 15 10">
                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg> <span>PRODUCT LIST</span>
                    </button>
                </div>
            </div>
            <div id="left" class="main row">
                <div class="toolbar white-in-black">
                    <div class="text-center customize-left-menu active" onclick="activeDecorateContent(event, 'logos')">
                        <img class="option-logo" src="./theme/img/decoration menu/logo.png"></img>
                        <a class="toolbarText">Logo</a>
                    </div>
                    <p class="text-center customize-left-menu" onclick="activeDecorateContent(event, 'textEditor')">
                        <img class="option-logo" src="./theme/img/decoration menu/text.png"></img>
                        <a class="toolbarText">Text</a>
                    </p>
                    <p class="text-center customize-left-menu" onclick="activeDecorateContent(event, 'contentPattern')">
                        <img class="option-logo" src="./theme/img/decoration menu/pattern.png"></img>
                        <a class="toolbarText">Pattern</a>
                    </p>
                    <p class="text-center customize-left-menu" onclick="activeDecorateContent(event, 'contentColor')">
                        <img class="option-logo" src="./theme/img/decoration menu/color.png"></img>
                        <a class="toolbarText">Color</a>
                    </p>
                </div>
                <div class="decorate col-md-4" id="root">
                   
                   
                </div>
              
                <div id="right" class="present col-md-8">
                    
                    <div class="product-image" id="container" ondrop="drop(event)" ondragover="allowDrop(event)">
                       
                    </div>
                    <div class="row margin-zero">
                        <div id="styleInfo" class="hidden" ></div>
                        <div class="col-md-6">
                            <div class="product-info">
                                <h5 class="present-title">W-4</h5>
                                <div class="present-emphasis ">Antiviral 3 Layers</div>
                                <p class="text-description ">
                                    3 layer filter with antiviral and antimicrobial technology making the mask extra resistent to virues and bacteria.
                                </p>
                            </div>
                        </div>
                        <div class=" col-md-6">
                            <div class="product-share ">
                                <div class="product-share-text text-description scrollbar medium-scrollbar">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <span class="mt-2">Click the “Share Your Design” to generate the link for sharing</span>
                                </div>
                                <div class="product-share-btn px-3 py-1 mt-1">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                                    <span class="fadeshow-md ml-1 mt-2">Share Your Design</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
      }
}

export default Main;