import React, { Component } from 'react';
import Slider from '@material-ui/lab/Slider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Grid, Step } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReactTooltip from 'react-tooltip'


const styles = {
  root: {
    width: 300,
  },
  slider: {
    padding: '10px',
    marginBottom:'10px'
  },
  track: {
    height: 4,
    borderRadius: 2,
},
thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -9,
    marginLeft: -11,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
        boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#fff",
},
};
  


export default class  Logo extends Component
{
  
  
  constructor(props)
  {
    super(props);
    this.state=
    {
        scale:props.scale,
        rotate:props.rotate
    };
    
  }
  
  
    handleChangeScale = (event, value) => {
        this.setState({ scale:value });
        this.props.onScaleChanged(value);
      };
      handleChangeRotate = (event, value) => {
        this.setState({ rotate:value });
        this.props.onRotateChanged(value);
      };

      dragStart =(event)=>
      {
        console.log('darg Start');
        this.props.onClick();
      };

    render()
    {
        const activeClass = this.props.active?"active logoCard ":"logoCard ";   
        return(
            <div className={activeClass} onClick={() => this.props.onClick()}>
              
                    <div className='avartar-logo' draggable="true">
                        <img className="avatar" src={this.props.image} alt="Product image" draggable="true" onDragStart={(event)=>this.dragStart(event)}/>
                    </div>
                    <div className='avarta-information'>
                      
                        {this.props.isNew?(
                         <div>
                           <p className="logoTitle">{this.props.title}</p>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7801 8.46976L14.2499 4.93958L13.1894 6.00006L15.4394 8.25002H9.74998V2.56063L11.9999 4.81059L13.0604 3.75011L9.53024 0.219933C9.23699 -0.073311 8.763 -0.073311 8.46976 0.219933L4.93958 3.75011L6.00006 4.81059L8.25002 2.56063V8.25002H2.56063L4.81059 6.00006L3.75011 4.93958L0.219933 8.46976C-0.073311 8.763 -0.073311 9.23699 0.219933 9.53024L3.75011 13.0604L4.81059 11.9999L2.56063 9.74998H8.25002V15.4394L6.00006 13.1894L4.93958 14.2499L8.46976 17.7801C8.61601 17.9263 8.808 17.9998 9 17.9998C9.192 17.9998 9.38399 17.9263 9.53024 17.7801L13.0604 14.2499L11.9999 13.1894L9.74998 15.4394V9.74998H15.4394L13.1894 11.9999L14.2499 13.0604L17.7801 9.53024C18.0733 9.23699 18.0733 8.763 17.7801 8.46976Z" fill="white"/>
</svg>

                           <p className='logoDescription'>{this.props.subTitle}</p>
                           
                         </div> 
                        ):
                        (<div>
                            <div className='logoActionButton'>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.props.onClear}>
                                  <rect width="24" height="24" fill="#DBECFF"/>
                                  <path d="M12 7C10.0572 7.00104 8.15975 7.5262 6.55456 8.50715C4.94937 9.4881 3.71079 10.8794 3 12.5C3.71079 14.1206 4.94937 15.5119 6.55456 16.4928C8.15975 17.4738 10.0572 17.999 12 18C13.9428 17.999 15.8403 17.4738 17.4454 16.4928C19.0506 15.5119 20.2892 14.1206 21 12.5C20.2892 10.8794 19.0506 9.4881 17.4454 8.50715C15.8403 7.5262 13.9428 7.00104 12 7ZM12 16.1667C10.915 16.1667 9.87448 15.7804 9.10729 15.0927C8.3401 14.4051 7.90909 13.4725 7.90909 12.5C7.90909 11.5275 8.3401 10.5949 9.10729 9.90728C9.87448 9.21964 10.915 8.83333 12 8.83333C13.085 8.83333 14.1255 9.21964 14.8927 9.90728C15.6599 10.5949 16.0909 11.5275 16.0909 12.5C16.0909 13.4725 15.6599 14.4051 14.8927 15.0927C14.1255 15.7804 13.085 16.1667 12 16.1667ZM12 10.3C11.349 10.3 10.7247 10.5318 10.2644 10.9444C9.80406 11.3569 9.54545 11.9165 9.54545 12.5C9.54545 13.0835 9.80406 13.6431 10.2644 14.0556C10.7247 14.4682 11.349 14.7 12 14.7C12.651 14.7 13.2753 14.4682 13.7356 14.0556C14.1959 13.6431 14.4545 13.0835 14.4545 12.5C14.4545 11.9165 14.1959 11.3569 13.7356 10.9444C13.2753 10.5318 12.651 10.3 12 10.3Z" fill="#101010"/>
                              </svg>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.props.onDelete}>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90909 9.34094C7.3233 9.34094 7.65909 9.67673 7.65909 10.0909V17.7273C7.65909 17.8659 7.71416 17.9989 7.81219 18.0969C7.91022 18.195 8.04318 18.25 8.18182 18.25H15.8182C15.9568 18.25 16.0898 18.195 16.1878 18.0969C16.2858 17.9989 16.3409 17.8659 16.3409 17.7273V10.0909C16.3409 9.67673 16.6767 9.34094 17.0909 9.34094C17.5051 9.34094 17.8409 9.67673 17.8409 10.0909V17.7273C17.8409 18.2638 17.6278 18.7783 17.2485 19.1576C16.8691 19.5369 16.3546 19.75 15.8182 19.75H8.18182C7.64535 19.75 7.13087 19.5369 6.75153 19.1576C6.3722 18.7783 6.15909 18.2638 6.15909 17.7273V10.0909C6.15909 9.67673 6.49487 9.34094 6.90909 9.34094Z" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 7.54541C4.25 7.1312 4.58579 6.79541 5 6.79541H19C19.4142 6.79541 19.75 7.1312 19.75 7.54541C19.75 7.95962 19.4142 8.29541 19 8.29541H5C4.58579 8.29541 4.25 7.95962 4.25 7.54541Z" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V15.8182C12.75 16.2324 12.4142 16.5682 12 16.5682C11.5858 16.5682 11.25 16.2324 11.25 15.8182V12C11.25 11.5858 11.5858 11.25 12 11.25Z" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45453 11.25C9.86874 11.25 10.2045 11.5858 10.2045 12V15.8182C10.2045 16.2324 9.86874 16.5682 9.45453 16.5682C9.04032 16.5682 8.70453 16.2324 8.70453 15.8182V12C8.70453 11.5858 9.04032 11.25 9.45453 11.25Z" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5455 11.25C14.9597 11.25 15.2955 11.5858 15.2955 12V15.8182C15.2955 16.2324 14.9597 16.5682 14.5455 16.5682C14.1313 16.5682 13.7955 16.2324 13.7955 15.8182V12C13.7955 11.5858 14.1313 11.25 14.5455 11.25Z" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.70453 5C8.70453 4.58579 9.04032 4.25 9.45453 4.25H14.5454C14.9597 4.25 15.2954 4.58579 15.2954 5V7.54545C15.2954 7.95967 14.9597 8.29545 14.5454 8.29545C14.1312 8.29545 13.7954 7.95967 13.7954 7.54545V5.75H10.2045V7.54545C10.2045 7.95967 9.86874 8.29545 9.45453 8.29545C9.04032 8.29545 8.70453 7.95967 8.70453 7.54545V5Z" fill="white"/>
                                </svg>

                            </div>
                            <div>
                                <p className='logoScaleRotation'>Scale {this.state.scale}</p>
                                  <Slider value={this.state.scale} onChange={this.handleChangeScale}  min={0} max={100} step={1} style={styles.slider}></Slider>
                            </div>
                            <div >
                              <p className='logoScaleRotation'>Rotate {this.state.rotate} degrees</p>
                              <Slider  value={this.state.rotate} onChange={this.handleChangeRotate} min={-180} max={180} step={1} style={styles.slider} ></Slider>
                            </div>
                        </div>
                        )
                        }
                        
                    </div>
            </div>
        );
    }
}

