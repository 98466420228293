import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ProductList from './Product'
import ItemList from './ItemList'
import LogoList from './decal/LogoList'
import ReactDOM from 'react-dom';
import ProductInfo from './ProductInfo'
import LogoTextList from './TextEditor/LogoTextList'
import ColorPattern from './ColorPattern';
import PantoneColor from './PantoneColor';
import LogoPattern from './pattern/LogoPattern';
import Sharing from './sharing/sharing';

class App extends Component {

  
 
  constructor(props)
  {
    super(props);
    this.state = {
      menuActive:[true,false,false,false,false],
      isShowMenu:true,
      isShowMenuToolbar:true,
      isShowProduct:false,
      isShowSharing:false,
      isLoaded: false,
      productIdx: 0,
      products:[]
    };
      
    //let product = this.props.data.Products[0];
    //this.props.onStyleChanged(product);
  }

  componentDidMount()
      {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('decal')) {
          this.setState({isShowMenu:false,isShowMenuToolbar:false,isShowProduct:true});
            this.loadSharingProduct(urlParams.get('decal'));
        }
        else {        
         fetch(window.backEnd+"/umbraco/surface/products/GetAllProducts/"+window.productsId.toString())
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              products: result.Products
            });
            this.handleProductClick(0);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              errorLoad:error
            });
          }
        );
      }
    }
     

   loadSharingProduct(decal)
   {    
        fetch(window.backEnd+"/decal/" + decal + ".json")
        .then(res => res.json())
        .then(
          (result) => {   
            let _products=[];
            _products.push(result.product);
            this.setState({
              isLoaded: true,
              productIdx:0,
              products: _products
            });
            window.restoreDecals = result;
            this.handleProductClick(0);
          });    
        }   

   loadMeshAndDecal(modelDecals)
   {
      let products = this.state.products;
      for(let i=0;i<products.length;i++)
      {
          if(products[i].Id === modelDecals.productId)
          {
            window.restoreDecals = modelDecals.decals;
            this.handleProductClick(i);
            return;
          }
      }

      this.handleStyleClick(0);
   }

   handleOnOffMenu()
   {
    this.setState({isShowMenu:!this.state.isShowMenu});
    if(this.state.isShowMenu==false)
    {
      window.toolbarActive='';
    }
   }
  handleProductClick(index)
  {
    console.log("Product changed : " + index);
    this.setState({productIdx:index});
    let product = this.state.products[index];     

    //Render product information 
    if(product.colorIdx == null)
    {
      product.colorIdx =0;
      product.patternIdx = 0;
    }
    
    //ReactDOM.render(<ProductInfo data={product} onStyleChange={(colorIdx,patternIdx)=>this.onStyleChange(colorIdx,patternIdx)}></ProductInfo>,document.getElementById('styleInfo'));
    window.loadProduct(product);
  }
  

  
  onStyleChange(colorIdx,patternIdx)
  {
    let product = this.state.products[this.state.productIdx]; 
    product.colorIdx= colorIdx;
    product.patternIdx = patternIdx;
    window.loadProduct(product);
  }

  handleColorClick(index)
  {
    this.setState({colorIdx:index});
  }

  handleFrabricClick(index)
  {
    this.setState({fabricIdx:index});
  }

  renderHeaderBar()
  {
    if(this.state.isShowMenu)
    {
      return(
        <div className="decorate-title col-md-4" id="decorate-title">
            <button className="button-decorate-collapse" onClick={()=>this.handleOnOffMenu()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 17H20.25C20.664 17 21 17.336 21 17.75C21 18.164 20.664 18.5 20.25 18.5H11.25C10.836 18.5 10.5 18.164 10.5 17.75C10.5 17.336 10.836 17 11.25 17ZM20.25 6.5H11.25C10.836 6.5 10.5 6.164 10.5 5.75C10.5 5.336 10.836 5 11.25 5H20.25C20.664 5 21 5.336 21 5.75C21 6.164 20.664 6.5 20.25 6.5Z" fill="white"/>
                    <path d="M3 11.75L8.25 7.25V11H20.25C20.664 11 21 11.336 21 11.75C21 12.164 20.664 12.5 20.25 12.5H8.25V16.25L3 11.75Z" fill="white"/>
                    </svg>
                    
                <span className="fadeshow-sm decoration-editing-text mx-2 Sub-Title">Decoration Editing</span>
            </button>
        </div>
      );
    }
    else
    {
      return(
        <div className="decorate-title" id="decorate-title">
            <button className="button-decorate-collapse" onClick={()=>this.handleOnOffMenu()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 17H20.25C20.664 17 21 17.336 21 17.75C21 18.164 20.664 18.5 20.25 18.5H11.25C10.836 18.5 10.5 18.164 10.5 17.75C10.5 17.336 10.836 17 11.25 17ZM20.25 6.5H11.25C10.836 6.5 10.5 6.164 10.5 5.75C10.5 5.336 10.836 5 11.25 5H20.25C20.664 5 21 5.336 21 5.75C21 6.164 20.664 6.5 20.25 6.5Z" fill="white"/>
                    <path d="M3 11.75L8.25 7.25V11H20.25C20.664 11 21 11.336 21 11.75C21 12.164 20.664 12.5 20.25 12.5H8.25V16.25L3 11.75Z" fill="white"/>
                    </svg>
            </button>
        </div>
      );
    }
  }

  renderContentLeft()
  {
    var style ="panelToolbar";
    if(this.state.isShowMenu==false)
    {
      style="hidden";
    }
    return( 
      <div className={style}>
         <LogoList  id="logos" onClick={(i)=>this.handleLogoClick(i)}  type="logo" styleName="logo highlightHover"
            title="TO ADD THIS LOGO"
            subTitle="Drag and drop this logo to product by click and hold. Then move logo to the product."
            active={this.state.menuActive[0]}>
          </LogoList>

    
          <LogoTextList id="textEditor"    active={this.state.menuActive[1]}></LogoTextList>
          <LogoPattern id="contentPattern"  type="pattern"
            title="TO ADD THIS PATTERN"
            subTitle="Drag and drop this pattern to product by click and hold. Then move pattern to the product."
            active={this.state.menuActive[2]}>
          </LogoPattern>
          <PantoneColor active={this.state.menuActive[3]}></PantoneColor>
      </div>
    )
  }

  

  renderProductInfor()
  {
    if(this.state.isLoaded==false)
    return null;
    let product = this.state.products[this.state.productIdx]; 
    return(
    <div className='productContent'>
            <div className="product-info">
                        <h5 className="present-title">{product.TypeName}</h5>
                        <div className="present-emphasis ">{product.Name}</div>
                        <p className="text-description ">
						                {product.Description}
                        </p>
            </div>
              
                <div className="product-share ">
                        <div className="product-share-text text-description scrollbar medium-scrollbar">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                            <span className="mt-2">Click the “Share Your Design” to generate the link for sharing</span>
                        </div>
                        <div className="product-share-btn px-3 py-1 mt-1" onClick={()=>this.handleSharingOnOff()}>
                            <i className="fa fa-share-alt" aria-hidden="true"></i>
                            <span className="fadeshow-md ml-1 mt-2">Share Your Design</span>
                        </div>
                    </div>
            </div>);
  }

  renderBtnProductOnOff()
  {
    if(this.state.isShowProduct==true)
    {
      return <div className='btnBlack btnProduct'></div>
    }
    return( <button className='btnBlack btnProduct' onClick={()=>this.handleProductOnOff(true)}> 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66667 7.33333C4.58714 7.33333 5.33333 6.58714 5.33333 5.66667C5.33333 4.74619 4.58714 4 3.66667 4C2.74619 4 2 4.74619 2 5.66667C2 6.58714 2.74619 7.33333 3.66667 7.33333Z" fill="white"/>
<path d="M3.66667 13.9998C4.58714 13.9998 5.33333 13.2536 5.33333 12.3332C5.33333 11.4127 4.58714 10.6665 3.66667 10.6665C2.74619 10.6665 2 11.4127 2 12.3332C2 13.2536 2.74619 13.9998 3.66667 13.9998Z" fill="white"/>
<path d="M3.66667 20.6668C4.58714 20.6668 5.33333 19.9206 5.33333 19.0002C5.33333 18.0797 4.58714 17.3335 3.66667 17.3335C2.74619 17.3335 2 18.0797 2 19.0002C2 19.9206 2.74619 20.6668 3.66667 20.6668Z" fill="white"/>
<path d="M21.1667 6.50016H8.66666C8.20645 6.50016 7.83333 6.12704 7.83333 5.66683C7.83333 5.20662 8.20645 4.8335 8.66666 4.8335H21.1667C21.6269 4.8335 22 5.20662 22 5.66683C22 6.12704 21.6269 6.50016 21.1667 6.50016ZM21.1667 13.1668H8.66666C8.20645 13.1668 7.83333 12.7937 7.83333 12.3335C7.83333 11.8733 8.20645 11.5002 8.66666 11.5002H21.1667C21.6269 11.5002 22 11.8733 22 12.3335C22 12.7937 21.6269 13.1668 21.1667 13.1668ZM21.1667 19.8335H8.66666C8.20645 19.8335 7.83333 19.4608 7.83333 19.0002C7.83333 18.5396 8.20645 18.1668 8.66666 18.1668H21.1667C21.6269 18.1668 22 18.5396 22 19.0002C22 19.4608 21.6269 19.8335 21.1667 19.8335Z" fill="white"/>
</svg>
PRODUCTS LIST
</button>);
  }
  renderContentRight()
  {
    
    return(
      <div className='right present'>
        
            {this.renderBtnProductOnOff()}
            <div className="product-image" id="container" onDrop={(evt)=> window.drop(evt)} onDragOver={(evt)=> window.allowDrop(evt)}>
               
            </div>
            {this.renderProductInfor()}
        </div>
    )
  }
  setMenuActive(idx)
  {
    let menuActiveClone =this.state.menuActive;

    for(let i =0;i<menuActiveClone.length;i++)
    {
      menuActiveClone[i] = (idx==i);
    }
   
    if(idx ==0)//logo
    {
      window.toolbarActive = 'logo';
    }
    else if(idx==1)
    {
      window.toolbarActive= 'text';
    }
    else if(idx==2)
    {
      window.toolbarActive='pattern';
    }
    else if(idx==3)
    {
      window.toolbarActive='pattern';
    }
    window.enableChangeColor(menuActiveClone[3]);
    this.setState({isShowMenu:true,menuActive:menuActiveClone});
  }

  handleProductOnOff(productShow)
  {
    this.setState({isShowMenu:!productShow,isShowMenuToolbar:!productShow,isShowProduct:productShow});
  }
  renderProductListHeader(active)
  {
    let style='productListHeader';
    if(active === false)
    {
      style='hidden';
    }
    return(<div className={style} >
       <span className='title_1_products'>Products List</span>
       <button className='productClosed' onClick={()=>this.handleProductOnOff(false)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_631_43869)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.15147 5.15127C5.62011 4.68265 6.3799 4.68265 6.84853 5.15127L12 10.3028L17.1515 5.15127C17.6201 4.68265 18.3799 4.68265 18.8485 5.15127C19.3171 5.61991 19.3171 6.3797 18.8485 6.84834L13.697 11.9998L18.8485 17.1513C19.3171 17.6199 19.3171 18.3797 18.8485 18.8483C18.3799 19.3169 17.6201 19.3169 17.1515 18.8483L12 13.6968L6.84853 18.8483C6.3799 19.3169 5.62011 19.3169 5.15147 18.8483C4.68285 18.3797 4.68285 17.6199 5.15147 17.1513L10.303 11.9998L5.15147 6.84834C4.68285 6.3797 4.68285 5.61991 5.15147 5.15127Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_631_43869">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          </button>
     
    </div>);
  }
  renderDecorationEditing()
  {
    let style='decorationEditing';
    if(this.state.isShowProduct===true)
    {
      style=' hidden';
    }
    else if(this.state.isShowMenu== false)
    {
      style+=' collapse';
    }
    return(<button className={style} onClick={()=>this.handleOnOffMenu()}>
      
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_623_41627)">
      <path d="M11.25 17H20.25C20.664 17 21 17.336 21 17.75C21 18.164 20.664 18.5 20.25 18.5H11.25C10.836 18.5 10.5 18.164 10.5 17.75C10.5 17.336 10.836 17 11.25 17ZM20.25 6.5H11.25C10.836 6.5 10.5 6.164 10.5 5.75C10.5 5.336 10.836 5 11.25 5H20.25C20.664 5 21 5.336 21 5.75C21 6.164 20.664 6.5 20.25 6.5Z" fill="white"/>
      <path d="M3 11.75L8.25 7.25V11H20.25C20.664 11 21 11.336 21 11.75C21 12.164 20.664 12.5 20.25 12.5H8.25V16.25L3 11.75Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_623_41627">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
    {this.state.isShowMenu? <span className='title_1'>Decoration Editing</span>:null}
     
  </button>);
  }

  renderToolbar()
  {
    let style='toolbar';
    if(this.state.isShowMenuToolbar===false)
    {
      style='hidden';
    }
    return(
      <div className={style}>
          <div className='leftToolbar'>
            <p className={this.state.menuActive[0]?'text-center customize-left-menu active':'text-center customize-left-menu'} onClick={()=>this.setMenuActive(0)}>
                  <img className="option-logo" src="/theme/img/decoration menu/logo.png"></img>
                  <a className="toolbarText">Logo</a>
              </p>
              <p  className={this.state.menuActive[1]?'text-center customize-left-menu active':'text-center customize-left-menu'} onClick={()=>this.setMenuActive(1)}>
                  <img className="option-logo" src="/theme/img/decoration menu/text.png"></img>
                  <a className="toolbarText">Text</a>
              </p>
              <p className={this.state.menuActive[2]?'text-center customize-left-menu active':'text-center customize-left-menu'} onClick={()=>this.setMenuActive(2)}>
                  <img className="option-logo" src="/theme/img/decoration menu/pattern.png"></img>
                  <a className="toolbarText">Pattern</a>
              </p>
              <p  className={this.state.menuActive[3]?'text-center customize-left-menu active':'text-center customize-left-menu'} onClick={()=>this.setMenuActive(3)}>
                  <img className="option-logo" src="/theme/img/decoration menu/color.png"></img>
                  <a className="toolbarText">Color</a>
              </p>
          </div>
          {this.renderContentLeft()}
        </div>
    )
  }
  handleSharingOnOff()
  {
    let isShow = !this.state.isShowSharing;
   this.setState({isShowSharing:isShow});
  }
  renderLeft()
  {
    
  }
  render()
  {
    let style='left';
    if(this.state.isShowMenu===false && this.state.isShowMenuToolbar==true)
    {
      style+= ' collapse';
    }
    return(
      <div className='flexContainer mainContent'>
      <div className={style}>
        {this.renderProductListHeader(this.state.isShowProduct)}
        <ProductList id="products" onClick={(i)=>this.handleProductClick(i)} styles={this.state.products} active={this.state.isShowProduct}></ProductList>
        {this.renderDecorationEditing()}
        {this.renderToolbar()}
      </div>
      {this.renderContentRight()}
      {this.state.isShowSharing?<Sharing onClosed={()=>this.handleSharingOnOff()}></Sharing>:null}
    </div>);
  }
  render1() {
    if(this.state.isShowProduct)
    {
      return(<div className='flexContainer mainContent'>
      <div className='left'>
        {this.renderProductListHeader()}
        <ProductList id="products" onClick={(i)=>this.handleProductClick(i)} styles={this.state.products} active={this.state.menuActive[4]}></ProductList>
      </div>
      {this.renderContentRight()}
    </div>);
    }
    else if(this.state.isShowMenu)
    {
      return(<div className='flexContainer mainContent'>
        <div className='left'>
          {this.renderDecorationEditing()}
          {this.renderToolbar()}
        </div>
        {this.renderContentRight()}
      </div>);
    }
    else
    {
      return(<div className='flexContainer mainContent'>
      <div className='left_toolbar'>
        {this.renderDecorationEditing()}
        {this.renderToolbar()}
      </div>
      {this.renderContentRight()}
    </div>);
    }
  }
}

export default App;
