import React, { Component } from 'react';
import { blue } from '@material-ui/core/colors';


export default class PMS extends Component
{
    constructor(props)
    {
        super(props);
        this.state=
        {
            active:-1,
            isLoaded:false,
            colors:null,            
        };

        this.selectedItem = this.selectedItem.bind(this);
        
    }

    RGBToHex(r,g,b) {
        var color = {
          "rgb":{
            "r":r,
            "g":g,
            "b":b,
            "a":100
          }
        }
        return color;
      }

    componentDidMount()
    {
       
        if(this.state.isLoaded === true)
            return;
            console.log("Load PMS");
        fetch(window.backEnd+"/js/pms.json")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              colors: result
            });
          });
    }

    selectedItem(idx)
    {
        var color = this.state.colors[idx];            
        this.props.onColorChanged(color.rgba);
        this.setState({active:idx});
    }

  

    render()
    {
      console.log(this.state.isLoaded);
      console.log(this.props.active);
      
        if(this.state.isLoaded && this.props.active==true)
        {
            
            let active = this.state.active;
            var listItem = this.state.colors.map(function(color,index)
            {
                var styleColor ="rgba("+color.rgba[0]+","+color.rgba[1]+","+color.rgba[2]+","+color.rgba[3]+")";
           
                var txtStyle="colorPantone";

                if(active === index)
                {
                    txtStyle ="colorBorder colorPantone";
                 
                }
                return <div className={txtStyle} style={{backgroundColor:styleColor}} key={index} onClick={()=> this.selectedItem(index)} >
                        {color.name}
                    </div>
            }, this);
            return  <div className="pms  scrollbar_1">{listItem}</div>
        }
        else
        {
            return <div></div>
        }
        
    }
}