import React, { Component } from 'react';

class Product extends Component
{
    render()
    {
        const activeClass = this.props.active?"productcard active":"productcard";
        return(
            <div className={activeClass} onClick={() => this.props.onClick()}>
									<img className='product_image' src={this.props.img} alt="Product image"/>
                                    <div className='product_group_info'>
                                        <p className='product_name'>{this.props.productName}</p>
                                        <span className="product_type_name">{this.props.typeName}</span>
                                        <p className='product_description'>{this.props.description}</p>
                                    </div>
								</div>
        );
    }
}


export default class ProductList extends Component
{
    constructor(props) {
        super(props);
        this.state = {
          active: 0,
        };
      }

      handleOnClick(key)
      {
          this.setState({active:key});
          if(this.props.onClick != null)
          {
            this.props.onClick(key);
          }
      }


    render()
    {
        
        let styles = this.props.styles;       
                   
        let activeIndex = this.state.active;
     
        var listItem = styles.map(function(model,index)
        {
            return <Product img={model.Thumbnail} onClick={()=>this.handleOnClick(index)} 
            typeName={model.TypeName} 
            productName={model.Name} 
            description={model.ShortDescription}
            price={model.Price}
            key={index}
            active={activeIndex === index}
            >
            </Product>
        },this);
        let style ="products scrollbar_1";
        if(this.props.active===false)
        {
            style="hidden";
        }
        return(
            <div className={style} id={this.props.id}>
                {
                    listItem
                }
              
			</div>
        );
    }
}
