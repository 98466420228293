import React, { Component } from 'react';
import ColorPattern from './ColorPattern';
import { SketchPicker } from 'react-color';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PMS from './PMS';
export default class PantoneColor extends Component
{
    constructor(props)
    {
      super(props);
      this.state = {
		selectedColor:'red'
      };
    }

	handlePMSChangeComplete(color)
	{
		window.updateColor({r:color[0]/255.0,g:color[1]/255.0,b:color[2]/255.0});
	}

	handleChangeComplete(color)
	{
		console.log(color);
		this.setState({selectedColor:color});

		window.updateColor({r:color.rgb.r/255.0,g:color.rgb.g/255.0,b:color.rgb.b/255.0});
	}

	clearColors()
	{
		console.log("clearColors");
		window.clearColors();
	}

    render()
    {
		
		if(this.props.active ==false)
		{
			return null;
		}

      return(
          <div className='panelContainer '>
				<div className='colorPicker'>
					<span className='colorTitle'>Click on the Pantone to choose color or click on the Color Picker to choose a color. <br></br><br></br>Then click “Save” button to apply the product.<br></br></span>
								<Tabs forceRenderTabPanel={true}>
									<TabList>      
										<Tab>Pantone PMS Colors</Tab>
										<Tab>Color Picker</Tab>
									</TabList>
								
									<TabPanel>
												<PMS onColorChanged={this.handlePMSChangeComplete} active={this.props.active}></PMS>
										</TabPanel>
									<TabPanel>
										{this.props.active?<SketchPicker color={this.state.selectedColor} onChangeComplete={(color)=> this.handleChangeComplete(color) } />:null}
									</TabPanel>
	
								</Tabs>
				</div>
				<div className='panelBottomBar'> 
				  <button className='btnBlack' onClick={this.clearColors}>CLEAR COLOR</button>                      
                </div>     
			</div>
          
      )
    }
}
