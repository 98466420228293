import React, { Component } from 'react';

export default class  Sharing extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            isClosed:this.props.isClosed,
            step:"Uploading the logo",
            id:  new Date().getTime(),
            link:"",
            copyMessage:"Copy a link to share design",
            percent:0
        };
    }

    onFinished(link)
    {
       this.setState({step:link,link:link,percent:100,isFinished:true});
    }


    createSharedLink()
    {
        var sharedData={};
        sharedData.product=window.getProductId();
        sharedData.logos =window.getDecals();
        sharedData.texts =window.getTexts();
        sharedData.textures = window.getTextures();
        sharedData.colors = window.getColors();
        console.log(sharedData);
        if(sharedData.logos.length==0 && sharedData.texts.length ==0 && sharedData.textures.length==0 && sharedData.colors.length ==0)
        {
            this.setState({step:'Please update your design'});
            return;
        }
        var _data = JSON.stringify(sharedData);
        console.log(_data);
        var formData = new FormData();
        formData.append('decal', _data);
        formData.append('id', this.state.id);
        var request = new XMLHttpRequest();
        request.open("POST", window.backEnd+"/umbraco/surface/products/uploaddecal", true);
        request.onreadystatechange =(evt)=>{
            let res = evt.currentTarget;
            console.log(res);
            if (res.readyState == 4 && res.status == 200) {
                if (res.responseText.startsWith("error")) {
                    this.setState({step:'Upload data failed '+res.responseText});
                } else {                 
                    let link = window.location.origin+res.responseText;
                    this.onFinished(link);
                }
            }
        };

        request.send(formData);

    }

    componentDidMount()
    {
        this.setState({step:"Uploading logo",percent:0});
        this.uploadLogo(0,()=>{
            this.setState({step:"Uploading pattern",percent:50});
            this.uploadPattern(0,()=>{
                this.setState({step:"Generating sharing data",percent:80});
                this.createSharedLink();
            });
        });

        
    }

    uploadPattern(idx,onFinished) {
        if(window.mesh == null || window.mesh.length == 0)
        {
            onFinished();
            return;
        }
        let percentCal = (idx)*100/window.mesh.length;
        let id = this.state.id+(idx+1)*100;

        this.setState({percent:percentCal});
        if (idx < window.mesh.length) {
            var file = window.mesh[idx].patternFile;
            if (file != null &&  window.mesh[idx].updatedTexture != null) {
                var formData = new FormData();
                formData.append('file', file);
                formData.append('id', id);
                var request = new XMLHttpRequest();
                request.open("POST", window.backEnd+"/umbraco/surface/products/uploadlogo", true);
                request.onreadystatechange = (event) => { 
                    console.log(event);
                    if (event.currentTarget.readyState == 4 && event.currentTarget.status == 200) {
                        if (event.currentTarget.responseText.startsWith("error")) {
                            this.setState({step:'Upload logo failed '+event.currentTarget.responseText});
                        } else {
                            window.mesh[idx].updatedTexture = event.currentTarget.responseText;
                            this.uploadPattern(++idx,onFinished);
                        }
                    }
                }
                    
                request.send(formData);
    
            } else {
                this.uploadPattern(++idx,onFinished);
            }
        } else {
            onFinished();
        }
    }

    uploadLogo(idx,onFinished) {
        if(window.decals == null || window.decals.length == 0)
        {
            onFinished();
            return;
        }
        let percentCal = (idx)*100/window.decals.length;
        let id = this.state.id+(idx+1)*10;
        this.setState({percent:percentCal});
        if (idx < window.decals.length) {
            var file = window.decals[idx].logo.file;
            if (file != null) {
                var formData = new FormData();
                formData.append('file', file);
                formData.append('id', id);
                var request = new XMLHttpRequest();
                request.open("POST", window.backEnd+"/umbraco/surface/products/uploadlogo", true);
                request.onreadystatechange = (event) => { 
                    console.log(event);
                    if (event.currentTarget.readyState == 4 && event.currentTarget.status == 200) {
                        if (event.currentTarget.responseText.startsWith("error")) {
                            this.setState({step:'Upload logo failed '+event.currentTarget.responseText});
                        } else {
                            window.decals[idx].logo.Thumbnail = event.currentTarget.responseText;
                            this.uploadLogo(++idx,onFinished);
                        }
                    }
                }
                    
                request.send(formData);
    
            } else {
                this.uploadLogo(++idx,onFinished);
            }
        } else {
            onFinished();
        }
    }
    handleCloseDialog()
    {
        this.setState({isClosed:true});
    }

    copyLinkToMem()
    {
        window.copyToClipboard(this.state.link);
        this.setState({copyMessage:'Link copied'});
    }
    renderCopyLink()
    {
        if(this.state.isFinished)
        {
            return(<div className="product-share-btn px-3 py-1 mt-1" onClick={()=>this.copyLinkToMem()}>
                <i className="fa fa-share-alt" aria-hidden="true"></i>
                <span  onClick={()=>this.copyLinkToMem()}> {this.state.copyMessage}</span>
            </div>);
        }
        else
        {
            return null;
        }
    }
    render()
    {
        if(this.state.isClosed===true)
        {
            return null;
        }

        return(<div className='sharingDialog'>
                <div className='sharingHeader' >
                    <span className='title_1_products'>Sharing your design</span>
                    <button className='productClosed' onClick={()=>this.props.onClosed()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_631_43869)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.15147 5.15127C5.62011 4.68265 6.3799 4.68265 6.84853 5.15127L12 10.3028L17.1515 5.15127C17.6201 4.68265 18.3799 4.68265 18.8485 5.15127C19.3171 5.61991 19.3171 6.3797 18.8485 6.84834L13.697 11.9998L18.8485 17.1513C19.3171 17.6199 19.3171 18.3797 18.8485 18.8483C18.3799 19.3169 17.6201 19.3169 17.1515 18.8483L12 13.6968L6.84853 18.8483C6.3799 19.3169 5.62011 19.3169 5.15147 18.8483C4.68285 18.3797 4.68285 17.6199 5.15147 17.1513L10.303 11.9998L5.15147 6.84834C4.68285 6.3797 4.68285 5.61991 5.15147 5.15127Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_631_43869">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </button>
                </div>
            <div className='messageSharing'>
                    <p className='logoDescription'>{this.state.step}</p>
                    {this.renderCopyLink()}
            </div>
           
            <progress className='progressBarSharing' value={this.state.percent} max="100"> {this.state.percent} % </progress>
        </div>);
    }
}