import React, { Component } from 'react';
import Slider from '@material-ui/lab/Slider';
import Carousel from 'react-elastic-carousel'


const styles = {
    slider: {
      padding: '10px 0px',
      color: 'white'
    }
  };




export default class  ColorPattern extends Component
{
    constructor(props) {
        super(props);
        this.state = {
          active: this.props.colorIdx,
          patternIdx:this.props.patternIdx,
          patternScale:50,
          patternLogo:[]
        };
      }

      componentDidMount()
      {
        fetch(window.backEnd+"/umbraco/surface/products/GetAllPattern")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              patternLogo: result
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
      }


      handleChangePatternScale(scale)
      {
        this.setState({patternScale:scale});
        window.patternScale(scale);
      }

    colorItemClick(idx)
    {
        this.setState({active:idx, patternIdx:0});
        if(this.props.onStyleChange != null)
        {
          this.props.onStyleChange(idx,0);
        }
    }

    patternItemClick(idx)
    {
        this.setState({patternIdx:idx});
        if(this.props.onStyleChange != null)
        {
          this.props.onStyleChange(this.state.active,idx);
          
        }
    }

    renderColors()
       {
        var colors = this.props.data.map(function(color,index)
        {
            let classText =(index===this.state.active)?"color-item color-active":"color-item";
            return <div className={classText} key={index} > 
                      <a  className="color-item" href="#" style={{background:color.StyleColor}} onClick={()=>this.colorItemClick(index)}></a> 
                    </div>
        },this);

        if(this.props.data.length<=3)
        {
          return <div style={{paddingTop:"5px"}}>                     
                      {colors}
                </div>
        }
        else 
        {
          
          return <div style={{paddingTop:"5px"}}> 
                    <Carousel itemsToShow={3} itemsToSroll={1} focusOnSelect={true} itemPadding={[1,1]} >
                      {colors}
                    </Carousel>
                </div>
        }
      }

      renderPatternScale()
      {
        return  <div className="patternSlider">
        <span>Pattern Size</span>
        <Slider value={this.state.patternScale} onChange={(event, value)=>this.handleChangePatternScale(value)} min={0} max={100} step={1} style={styles.slider}></Slider>
        </div>
      }

    renderPatterns()
    {
      let patternData = this.props.data[this.state.active].Patterns;
      if(patternData == null || patternData.length ===0)
      {
        return <div></div>
      }

      let patterns = patternData.map(function(pattern,index){
        let classText =(index===this.state.patternIdx)?"color-item color-active":"color-item";
        return <div className={classText} key={index} >
                  <a data-toggle={pattern.PatternName} className="pattern-item" href="#" onClick={()=>this.patternItemClick(index)}>
                      <img width="50px" height="50px" src={pattern.PatternThumbnail}></img>                      
                      </a>
                  </div>},this);

        if(patternData.length <=3)
        {
            return <div  style={{paddingTop:"10px"}}> <h3>Pattern - {patternData[this.state.patternIdx].PatternName}</h3>{patterns}</div>
        }
        else
        {
          return <div  style={{paddingTop:"10px"}}> 
                    <h3>Pattern - {patternData[this.state.patternIdx].PatternName}</h3> 
                        <Carousel itemsToShow={3} itemsToSroll={1} >
                          {patterns}
                        </Carousel>
                </div>
        }
    }

    dragStart =(event,index)=>
    {
      window.updatePattern(this.state.patternLogo[index]);
    };

    addLogo(e)
    {
      if(e.target !==null)
          {
            if(e.target.files.length >0)
            {
              var id=0;
             this.state.patternLogo.forEach(element => {
               if(id<element.Id)
                  id=element.Id;
             });
             id+=1;

              var pattern={"Id":id,"Thumbnail":URL.createObjectURL(e.target.files[0]),"ThumbnailImg":URL.createObjectURL(e.target.files[0]),"Scale":10,"Rotate":0,"file":e.target.files[0], "IsPattern":true};
              var _logos = this.state.patternLogo;
              _logos.push(pattern);
              this.setState({patternLogo:_logos});       
            }
          }
    }

    renderAddLogo()
    {
        return <div className="color-item" key={999} >
           <a  className="pattern-item" >
            <input type="file" id="myfile_pattern" name="myfile" accept="image/*" onChange={(e)=>this.addLogo(e)} value=""/>
              <img width="50px" height="50px" src="\Zion3D\image\add.png"></img>  
 
            </a>

        
      </div>     
    }

    renderPatternLogo()
    {
      let patternData = this.state.patternLogo;
      if(patternData == null || patternData.length ===0)
      {
        return <div></div>
      }

      let patterns = patternData.map(function(pattern,index){
        let classText =(index===this.state.patternIdx)?"color-item color-active":"color-item";
        return <div className={classText} key={index} >
                  <a  className="pattern-item" href="#" draggable="true" onDragStart={(event)=>this.dragStart(event,index)} data-toggle="tooltip" data-html="true" title="<em>Tooltip</em> <u>with</u> <b>HTML</b>">
                      <img width="50px" height="50px" src={pattern.ThumbnailImg}></img>                      
                      </a>
                  </div>},this);
      patterns.push(this.renderAddLogo());

          return <div  style={{paddingTop:"10px"}}> 
                    <h4>Drag-Drop onto the object for adding</h4> 
                        <Carousel itemsToShow={3} itemsToSroll={1} >                       
                          {patterns}                            
                        </Carousel>
                </div>
        
    }

    render()
    {      
        if(this.props.data !=null)
        {

        return(
            <li className="color" id={this.props.id}>              
                 {this.renderColors()}
                 {this.renderPatterns()}                  
                {this.renderPatternLogo()}
                   
				</li>
        );
            }
            else
            {
                return(
                <li className="color-empty" id={this.props.id}></li>
                );
            }
            
    }
}
