import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

export default class TextEdit extends Component
{
  constructor(props)
  {
    super(props);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.state = {
      text: this.props.text,
      color:this.props.color,
      font:this.props.font
    };
  }

  handleTextChange(event)
  {
    this.setState({text:event.target.value});
    if(this.props.text != this.state.text)
    {
      this.props.onTextChange(event.target.value);
    }
  }

  handleColorChange(color)
  {
    console.log(color);
    this.setState({ color: color.rgb });
    this.props.onColorChange(color);
  }

  createText(event)
    {
        if(window.textId == null || typeof(window.textId) == 'undefined')
        {
            window.textId =0;
        }
        let textPattern = {"Id": Math.random().toString(36).substr(2, 9),"Color":this.state.color,"Text":this.state.text,"Font":this.state.font,"IsText":true,"Scale":5,"Rotate":0,"isNew":true};
        this.props.onSubmit(textPattern);
    }


    render()
    {
      return(
        <div className="textEditorPanel">
              <div className='offTextEditor'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(evt)=> this.props.onClosed()}>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90909 9.34094C7.3233 9.34094 7.65909 9.67673 7.65909 10.0909V17.7273C7.65909 17.8659 7.71416 17.9989 7.81219 18.0969C7.91022 18.195 8.04318 18.25 8.18182 18.25H15.8182C15.9568 18.25 16.0898 18.195 16.1878 18.0969C16.2858 17.9989 16.3409 17.8659 16.3409 17.7273V10.0909C16.3409 9.67673 16.6767 9.34094 17.0909 9.34094C17.5051 9.34094 17.8409 9.67673 17.8409 10.0909V17.7273C17.8409 18.2638 17.6278 18.7783 17.2485 19.1576C16.8691 19.5369 16.3546 19.75 15.8182 19.75H8.18182C7.64535 19.75 7.13087 19.5369 6.75153 19.1576C6.3722 18.7783 6.15909 18.2638 6.15909 17.7273V10.0909C6.15909 9.67673 6.49487 9.34094 6.90909 9.34094Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 7.54541C4.25 7.1312 4.58579 6.79541 5 6.79541H19C19.4142 6.79541 19.75 7.1312 19.75 7.54541C19.75 7.95962 19.4142 8.29541 19 8.29541H5C4.58579 8.29541 4.25 7.95962 4.25 7.54541Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V15.8182C12.75 16.2324 12.4142 16.5682 12 16.5682C11.5858 16.5682 11.25 16.2324 11.25 15.8182V12C11.25 11.5858 11.5858 11.25 12 11.25Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45453 11.25C9.86874 11.25 10.2045 11.5858 10.2045 12V15.8182C10.2045 16.2324 9.86874 16.5682 9.45453 16.5682C9.04032 16.5682 8.70453 16.2324 8.70453 15.8182V12C8.70453 11.5858 9.04032 11.25 9.45453 11.25Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5455 11.25C14.9597 11.25 15.2955 11.5858 15.2955 12V15.8182C15.2955 16.2324 14.9597 16.5682 14.5455 16.5682C14.1313 16.5682 13.7955 16.2324 13.7955 15.8182V12C13.7955 11.5858 14.1313 11.25 14.5455 11.25Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.70453 5C8.70453 4.58579 9.04032 4.25 9.45453 4.25H14.5454C14.9597 4.25 15.2954 4.58579 15.2954 5V7.54545C15.2954 7.95967 14.9597 8.29545 14.5454 8.29545C14.1312 8.29545 13.7954 7.95967 13.7954 7.54545V5.75H10.2045V7.54545C10.2045 7.95967 9.86874 8.29545 9.45453 8.29545C9.04032 8.29545 8.70453 7.95967 8.70453 7.54545V5Z" fill="white"/>
                </svg>
              </div>
            <input id="textInput" type="text" value={this.state.text} placeholder="Typing your text"  onChange={(event) =>this.handleTextChange(event)}></input>
            <SketchPicker color={this.state.color } onChangeComplete={(color,evt)=> this.handleColorChange(color) }  />
            
            <button className="btnYellow" onClick={(event)=>this.createText(event)}>SAVE</button>
        

            </div>
      )
    }
} 