import React, { Component } from 'react';
import ColorPattern from './ColorPattern';
import { SketchPicker } from 'react-color';
import PMS from './PMS'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import Switch from '@material-ui/core/Switch';

export default class ProductInfo extends Component
{
	state = {
		background: '#ffffff',
		visible: 'none'
	  };
	
	  handleChangeComplete = (color) => {
		
		this.setState({ background: color.rgb});
		if(window.intersection != null && window.intersection.mesh != null)
		{
			window.intersection.mesh.material.color.setRGB(color.rgb.r/255.0,color.rgb.g/255.0,color.rgb.b/255.0);
			if(color.rgb.a <1)
			{
				window.intersection.mesh.material.transparent= true;
				window.intersection.mesh.material.opacity= color.rgb.a;
			}
			else
			{
				window.intersection.mesh.material.transparent= false;
				window.intersection.mesh.material.opacity= color.rgb.a;
			}
			window.intersection.mesh.material.emissive.setRGB(0,0,0); 
		}
	  };

	  handlePMSChangeComplete = (color) => {
		
		this.setState({ background: color.rgb});
		if(window.intersection != null && window.intersection.mesh != null)
		{
			window.intersection.mesh.material.color.setRGB(color.rgb.r/255.0,color.rgb.g/255.0,color.rgb.b/255.0);			
			window.intersection.mesh.material.emissive.setRGB(0,0,0); 
		}
	  };

	  handleChange = (color) => {
		//console.log(color);
		this.setState({ background: color.rgb});
	  };

	
	  handleCustomizeColor = () => {
		if(this.state.visible === 'none')
		{
			window.enableChangeColor(true);
			this.setState({visible:'block'});
		}
		else
		{
			window.enableChangeColor(false);
			this.setState({visible:'none'});
		}
	  };

	  renderColorPattern()
	  {
		  if(this.state.visible === "none")
		  {
			return <ColorPattern data={this.props.data.Styles} colorIdx={this.props.data.colorIdx} patternIdx={this.props.data.patternIdx} onStyleChange={(colorIdx,patternIdx)=>this.props.onStyleChange(colorIdx,patternIdx)}></ColorPattern>					
		  }
		  else
		  {
			return <div className="colorPicker">
				<span>First click on the object then select any color to change</span>
							<Tabs forceRenderTabPanel={true}>
								<TabList>      
									<Tab>Pantone PMS Colors</Tab>
									<Tab>Color Picker</Tab>
								</TabList>
							
								<TabPanel>
											<PMS onColorChanged={this.handlePMSChangeComplete}></PMS>
									</TabPanel>
								<TabPanel>
									<SketchPicker color={this.state.background } 
														onChangeComplete={ this.handleChangeComplete } />
									</TabPanel>
   
 							 </Tabs>
					</div>		
		  }
	  }
    render()
    {
		return(
			<div className="col-md-6">
                    <div className="product-info">
                        <h5 className="present-title">{this.props.data.TypeName}</h5>
                        <div className="present-emphasis ">{this.props.data.Name}</div>
                        <p className="text-description ">
						{this.props.data.Description}
                        </p>
                    </div>
                </div>
		);
    }
}