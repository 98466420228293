
import React, { Component } from 'react';
import Logo from './Logo'
import Slider from '@material-ui/lab/Slider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Grid, Step } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReactTooltip from 'react-tooltip'

export default class LogoList extends Component
{
    constructor(props) {
        super(props);
        this.state = {
          active: -1,
          isLoaded:false,
          logos:this.props.items,
          isPattern:this.props.type==="pattern"
        };

        window.updateTheLogo.push(this.updateTheLogo.bind(this));
        window.logoSelectionChanged.push(this.logoSelectionChanged.bind(this));

        console.log(this.state.logos);
        if(this.state.logos != null)
        {
          if(this.state.logos.length >0)
          {
            window.updateLogoSelect(this.state.logos[0]);
          }
        }
        else{
          this.state.logos = [];
        }
      }

      componentDidMount()
      {
        let url ="/umbraco/surface/products/GetAllPattern";
        if(this.props.type=="logo")
        {
          url = "/umbraco/surface/products/GetAllLogo";
        }

        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('decal') === false) {
          fetch(window.backEnd+url)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                logos: result
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
        }
      }


      updateTheLogo(logoTarget)
      {  
        
        //if(logoTarget.IsPattern === false)
        {
         
          let found = this.state.logos.find(logo=>logo.Id === logoTarget.Id && logo.Thumbnail === logoTarget.Thumbnail)
          if(found == null || found === undefined)
          {
            //console.log("Not found");
            return false;
             // this.state.logos.push(logoTarget);
          }
          else
          {
            console.log("found" );
            console.log(found);
          }
        
          this.forceUpdate();
          return true;
        }
      }

      logoSelectionChanged(logo)
      {  
        for(var i=0;i<this.state.logos.length;i++)
        {
          if(this.state.logos[i].Id === logo.Id)
          {
            if(this.state.active !== i)
            {
              this.setState({active:i});
            }
            return true;          
          }
        }
        this.setState({active:-1});
        return false;
      }

      handleOnClick(key)
      {
          this.setState({active:key});
          var logo = this.state.logos[key];    
          window.updateLogoSelect(logo);
      }

      handleClearAll(e)
      {        
        let logos = this.state.logos;
        logos.map(x=>x.Position=null);
        window.clearAllLogo();
        this.setState({active:this.state.active});
      }

      handleLogoFile(e)
      {
          if(e.target !==null)
          {
            if(e.target.files.length >0)
            {
              var id=0;
             this.state.logos.forEach(element => {
               if(id<element.Id)
                  id=element.Id;
             });
             id+=1;

              var logo={"Id":id,"Thumbnail":URL.createObjectURL(e.target.files[0]),"Scale":3,"Rotate":0,"file":e.target.files[0], "IsPattern":this.state.isPattern};
              var _logos = this.state.logos;
              _logos.unshift(logo);
              this.setState({logos:_logos,active:0});              
              window.updateLogoSelect(logo);
            }
          }
      }

      handleOnScaleChanged(index,value)
      {
          var logo = this.state.logos[index];
           logo.Scale= value;
           window.updateLogoChange(logo);

      }

      handleRotateChanged(index,value)
      {
        let logo = this.state.logos[index];
        logo.Rotate= value;
        window.updateLogoChange(logo);
      }

      handleOnDelete(index)
      {
        let logo = this.state.logos[index];
        let _logos = this.state.logos;
        _logos.splice(index,1);
        this.setState({logos:_logos,active:0});
        window.deleteLogo(logo);
      }

      handleOnClear(index)
      {
        let logo = this.state.logos[index];   
        logo.Position = null;     
        window.clearLogo(logo);
      }

     

    render()
    {
      if(this.props.active== false)
        return null;
      if(this.state.isLoaded)
      {
        let logos = this.state.logos;

        
                   
        const activeIndex = this.state.active;
     
        var listItem = logos.map(function(model,index)
        {
          let isnew = false;
          if(model.Position == null )
          {
            isnew = true;
          }
          else
          {
            isnew= false;
          }
            return <Logo scale={model.Scale} rotate={model.Rotate} image={model.Thumbnail} onClick={()=>this.handleOnClick(index)} 
            onScaleChanged={(value)=>this.handleOnScaleChanged(index,value)}             
            onRotateChanged={(value)=>this.handleRotateChanged(index,value)}
            key={index}
            active={activeIndex === index}
            isNew={isnew}
            onDelete={()=>this.handleOnDelete(index)}
            onClear={()=>this.handleOnClear(index)}
            title={this.props.title}
            subTitle={this.props.subTitle}
            >
            </Logo>
        },this);

        return(
            <div id={this.props.id}  className='panelContainer'>
                <ReactTooltip />
                <div className="logoHeader ">
                   
                    <Button className='logoBtnClear' onClick={(e)=>this.handleClearAll(e)} >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.90909 5.34094C3.3233 5.34094 3.65909 5.67673 3.65909 6.09094V13.7273C3.65909 13.8659 3.71416 13.9989 3.81219 14.0969C3.91022 14.195 4.04318 14.25 4.18182 14.25H11.8182C11.9568 14.25 12.0898 14.195 12.1878 14.0969C12.2858 13.9989 12.3409 13.8659 12.3409 13.7273V6.09094C12.3409 5.67673 12.6767 5.34094 13.0909 5.34094C13.5051 5.34094 13.8409 5.67673 13.8409 6.09094V13.7273C13.8409 14.2638 13.6278 14.7783 13.2485 15.1576C12.8691 15.5369 12.3546 15.75 11.8182 15.75H4.18182C3.64535 15.75 3.13087 15.5369 2.75153 15.1576C2.3722 14.7783 2.15909 14.2638 2.15909 13.7273V6.09094C2.15909 5.67673 2.49487 5.34094 2.90909 5.34094Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 3.54541C0.25 3.1312 0.585786 2.79541 1 2.79541H15C15.4142 2.79541 15.75 3.1312 15.75 3.54541C15.75 3.95962 15.4142 4.29541 15 4.29541H1C0.585786 4.29541 0.25 3.95962 0.25 3.54541Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 7.25C8.41421 7.25 8.75 7.58579 8.75 8V11.8182C8.75 12.2324 8.41421 12.5682 8 12.5682C7.58579 12.5682 7.25 12.2324 7.25 11.8182V8C7.25 7.58579 7.58579 7.25 8 7.25Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.45453 7.25C5.86874 7.25 6.20453 7.58579 6.20453 8V11.8182C6.20453 12.2324 5.86874 12.5682 5.45453 12.5682C5.04032 12.5682 4.70453 12.2324 4.70453 11.8182V8C4.70453 7.58579 5.04032 7.25 5.45453 7.25Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5455 7.25C10.9597 7.25 11.2955 7.58579 11.2955 8V11.8182C11.2955 12.2324 10.9597 12.5682 10.5455 12.5682C10.1313 12.5682 9.79547 12.2324 9.79547 11.8182V8C9.79547 7.58579 10.1313 7.25 10.5455 7.25Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.70453 1C4.70453 0.585786 5.04032 0.25 5.45453 0.25H10.5454C10.9597 0.25 11.2954 0.585786 11.2954 1V3.54545C11.2954 3.95967 10.9597 4.29545 10.5454 4.29545C10.1312 4.29545 9.79544 3.95967 9.79544 3.54545V1.75H6.20453V3.54545C6.20453 3.95967 5.86874 4.29545 5.45453 4.29545C5.04032 4.29545 4.70453 3.95967 4.70453 3.54545V1Z" fill="white"/>
</svg>

Clear all</Button>                        
                  
                </div>
                <div className='logoList scrollbar_1'>
                  {listItem}     
                </div>
                <div className='panelBottomBar'>
                  <span className='title_3_border'>+ ADD NEW</span>
                  <input className='fullOwner_opacity' type="file" id="myfile" name="myfile" accept="image/*" onChange={(e)=>this.handleLogoFile(e)} value="" />                      
                </div>         
			</div>
        );
    }

    return null;
  }
}
