import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'

class Item extends Component
{
    render()
    {
        const activeClass = this.props.active?"active item":"item";
        
        return(
            <div className={activeClass} onClick={() => this.props.onClick()}>
                <img src={this.props.image}  data-tip={this.props.tootltip}/>
                <br></br>
                <span style={{fontSize: '1.2em'}} >{this.props.title}</span>
            </div>
        );
    }
}


export default class ItemList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            activeIndex:0
        };
    }
    
    handleClick(index)
    {
        this.setState({activeIndex:index});
        if(this.props.onClick != null)
        {
            this.props.onClick(index);
        }
    }

    render()
    {
        var index = this.state.activeIndex;

        var itemList = this.props.items.map(
            function(item,key)
            {
                return(
                    <Item image={item.Thumbnail} title={item.Name} key={key} active={index === key} tootltip={item.Description} onClick={()=>this.handleClick(key)}></Item>
                );
            },this);
       
        return(
            <li className={this.props.styleName} >
                {itemList} 
                <ReactTooltip />
            </li>
        );

    }
}